import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Typography from "../../Typography";

const isScrolledIntoView = (el) => {
  const { top, bottom } = el.getBoundingClientRect();
  return top >= 0 && bottom <= window.innerHeight;
};
const FormError = ({ error, elementName = "", isScrollDisabled = false }) => {
  const messageRef = useRef(null);

  useEffect(() => {
    if (
      error &&
      messageRef.current &&
      !isScrollDisabled &&
      !isScrolledIntoView(messageRef.current)
    ) {
      messageRef.current.scrollIntoView();
    }
  }, [error, isScrollDisabled]);

  if (!error) return null;
  return (
    <div ref={messageRef}>
      <Typography themeName="errorSmall" elementName={elementName}>
        {error}
      </Typography>
    </div>
  );
};

export default FormError;
FormError.propTypes = {
  error: PropTypes.string,
  elementName: PropTypes.string,
  isScrollDisabled: PropTypes.bool
};
