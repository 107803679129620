import apiClient from "./api/apiClient";

async function validateRecaptcha(recaptchaData) {
  const validRecaptcha = await apiClient.post("/api/recaptcha", recaptchaData, {
    headers: { "Content-Type": "application/json" }
  });

  return validRecaptcha;
}

export default validateRecaptcha;
