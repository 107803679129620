import { gql } from "@apollo/client";

const buildGetOrderSearchV2 = () => {
  return gql`
    query OrderSearchV2($order: OrderFilter) {
      orderSearchV2(order: $order) {
        pageNumber
        pageSize
        resultCount
        data {
          class
          dateOrdered
          enteredBy
          orderId
          orderNumber
          itemCount
          subTotal
          firstName
          totalOrder
          webOrderNumber
          billingZip
          type
          shippingCost
          shippingAddress {
            addressee
            addr1
            addr2
            addr3
            state
            city
            zip
            phone
            country
          }
          orderStatus
          orderStatusText
          billingAddress {
            addressee
          }
          shipments {
            shippedVia
            shippedMethod
            shippedDate
            status
          }
        }
      }
    }
  `;
};

export default buildGetOrderSearchV2;
