const generateRecaptchaToken = async (recaptchaAction, executeRecaptcha) => {
  const recaptchaToken = await executeRecaptcha(recaptchaAction);

  const recaptchaData = {
    recaptchaToken,
    recaptchaAction
  };

  return recaptchaData;
};

export default generateRecaptchaToken;
