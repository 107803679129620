export const ALREADY_IN_USE_EMAIL_DEFAULT_MESSAGE = "Email already in use";
export const HAS_AT_LEAST_ONE_DEFAULT_MESSAGE =
  "Please check at least one of the checkboxes";
export const IS_EMPLOYEE_EMAIL_DEFAULT_MESSAGE =
  "Please contact the IT department to reset your password";
export const IS_MATCH_EMAIL_DEFAULT_MESSAGE =
  "Please enter a correct confirmation of email";
export const IS_MATCH_PASSWORD_DEFAULT_MESSAGE =
  "Please enter a correct confirmation of password";
export const IS_REQUIRED_DEFAULT_MESSAGE = "This field is required";
export const IS_VALID_EMAIL_DEFAULT_MESSAGE =
  "Please enter a valid email address.";
export const IS_VALID_PASSWORD_DEFAULT_MESSAGE =
  "Please enter a valid password.";
export const IS_VALID_PHONE_DEFAULT_MESSAGE =
  "Please specify a valid phone number.";
export const IS_VALID_POSTAL_CODE_DEFAULT_MESSAGE =
  "Sorry, that postal code is invalid.";
export const IS_VALID_ORDER_NUMBER_DEFAULT_MESSAGE =
  "Oops! Your order information isn’t available just yet. Please allow us 1 hour from the time you placed your order for the information to appear. Questions? You can call Customer Care at 866.597.2742.";
export const RECAPTCHA_DISCLAIMER_P1 =
  "This site is protected by reCAPTCHA and the Google ";
export const RECAPTCHA_DISCLAIMER_P2 = " and ";
export const RECAPTCHA_DISCLAIMER_P3 = " apply.";
