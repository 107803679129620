import React from "react";
import { RecaptchaText, RecaptchaTextLink } from "./RecaptchaDisclaimerStyled";
import {
  RECAPTCHA_DISCLAIMER_P1,
  RECAPTCHA_DISCLAIMER_P2,
  RECAPTCHA_DISCLAIMER_P3
} from "../../utils/formValidations/constants";
import PropTypes from "prop-types";

const RecaptchaDisclaimer = ({ maxWidth }) => {
  return (
    <RecaptchaText maxWidth={maxWidth}>
      {RECAPTCHA_DISCLAIMER_P1}
      <RecaptchaTextLink href="https://policies.google.com/privacy">
        Privacy Policy
      </RecaptchaTextLink>
      {RECAPTCHA_DISCLAIMER_P2}
      <RecaptchaTextLink href="https://policies.google.com/terms">
        Terms of Service
      </RecaptchaTextLink>
      {RECAPTCHA_DISCLAIMER_P3}
    </RecaptchaText>
  );
};

export default RecaptchaDisclaimer;

RecaptchaDisclaimer.propTypes = {
  maxWidth: PropTypes.number
};
