import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  Container,
  ButtonWrapper,
  SubmitButton,
  FormContainer,
  NewUserPopupParagraph,
  NewUserPopupParagraphWithBorderBottom,
  LoaderWrapper
} from "./SearchTrackOrderStyled";
import Text from "../Text";
import TextInput from "../FormComponents/TextInput";
import RichText from "../RichText";
import { EMPTY_SEARCH_TRACK_ORDER } from "../../constants/order";
import { validateZipCode } from "../../utils/validateZipCode";
import DEFAULT_ERROR_MESSAGES from "../../constants/formErrorMessages/searchTrackOrderFormErrors";
import { fetchOrderSearchV2 } from "../../gql/utils";
import { IS_VALID_ORDER_NUMBER_DEFAULT_MESSAGE } from "../../utils/formValidations/constants";
import Router from "next/router";
import { CircularLoader } from "../Icons/Variants";
import generateRecaptchaToken from "../../utils/generateRecaptchaToken";
import validateRecaptcha from "../../utils/validateRecaptcha";
import { INVALID_SUBMISSION } from "../../constants/formErrors";
import { FormError } from "../FormComponents";
import useUser from "../../hooks/useUser";
import RecaptchaDisclaimer from "../RecaptchaDisclaimer";

const DefaultMaxLength = 2147483647;
const HeaderTitleIndex = 0;
const HeaderSubTitleLevelOneIndex = 1;
const HeaderSubTitleLevelTwoIndex = 2;
const FooterLevelOneIndex = 0;
const FooterLevelTwoIndex = 1;
const FooterLevelThreeIndex = 2;
const orderNumberErrorMessage = IS_VALID_ORDER_NUMBER_DEFAULT_MESSAGE;

// eslint-disable-next-line
const SearchTrackOrder = ({
  Header,
  ButtonCheckStatus,
  SignInRichText,
  Footer,
  errorMessages = DEFAULT_ERROR_MESSAGES,
  UseReCaptcha
}) => {
  const [loading, setLoading] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const { executeRecaptcha } = UseReCaptcha();
  const user = useUser();

  const { orderNumber } = Router?.query ?? {};
  
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm({
    mode: "onBlur",
    defaultValues: EMPTY_SEARCH_TRACK_ORDER
  });
  const validCallback = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    const recaptchaData = await generateRecaptchaToken(
      "trackOrder",
      executeRecaptcha
    );
    const validRecaptcha = await validateRecaptcha(recaptchaData);
    if (validRecaptcha?.data?.message !== "Valid Recaptcha") {
      setLoading(false);
      setRecaptchaError(true);
      return;
    }
    const { orderNumber, billingZipCode } = data || {};
    const isWebOrder = isNaN(parseInt(orderNumber[0], 10));
    const orderSearchResults = await fetchOrderSearchV2({
      orderNumber,
      isWebOrder,
      billingZipCode
    });
    const orderNumberExists = orderSearchResults?.data?.length > 0;
    if (!orderNumberExists) {
      setError("orderNumber", {
        type: "notFound",
        message: orderNumberErrorMessage
      });
      setLoading(false);
      return;
    }
    const orderId = orderSearchResults?.data[0]?.orderId;
    const zip = orderSearchResults?.data[0]?.billingZip;

    Router.push(`/trackmyorder?orderid=${orderId}&zipcode=${zip}`);
  };

  return (
    <Container>
      {!loading ? (
        <React.Fragment>
          <Text {...Header?.[HeaderTitleIndex]} />
          <Text {...Header?.[HeaderSubTitleLevelOneIndex]} />
          <Text {...Header?.[HeaderSubTitleLevelTwoIndex]} />
          {recaptchaError && <FormError error={INVALID_SUBMISSION} />}
          <FormContainer onSubmit={handleSubmit(validCallback)}>
            <TextInput
              {...register("orderNumber", {
                required: errorMessages?.orderNumber?.emptyError
              })}
              value={orderNumber}
              maxLength={DefaultMaxLength}
              error={errors?.orderNumber?.message}
              placeholder="Order Number"
              label="Order Number*"
            />
            <TextInput
              {...register("billingZipCode", {
                required: errorMessages?.billingZipCode?.emptyError,
                validate: {
                  invalidError: (value) =>
                    validateZipCode(value) ||
                    errorMessages?.billingZipCode?.invalidError
                }
              })}
              placeholder="Billing Zip Code"
              label="Billing Zip Code*"
              maxLength={DefaultMaxLength}
              type="text"
              error={errors?.billingZipCode?.message}
            />
            <ButtonWrapper>
              <SubmitButton type="submit">
                {ButtonCheckStatus?.ButtonCheckStatusText}
              </SubmitButton>
            </ButtonWrapper>
            <RecaptchaDisclaimer maxWidth={350} />
          </FormContainer>
          {!user?.isLoggedIn && (
            <NewUserPopupParagraphWithBorderBottom>
              <RichText
                richText={SignInRichText?.richText}
                themeName={SignInRichText?.themeName}
              />
            </NewUserPopupParagraphWithBorderBottom>
          )}

          <NewUserPopupParagraph>
            <RichText
              richText={Footer?.[FooterLevelOneIndex]?.richText}
              themeName={Footer?.[FooterLevelOneIndex]?.themeName}
            />
          </NewUserPopupParagraph>
          <NewUserPopupParagraph>
            <RichText
              richText={Footer?.[FooterLevelTwoIndex]?.richText}
              themeName={Footer?.[FooterLevelTwoIndex]?.themeName}
            />
          </NewUserPopupParagraph>
          <NewUserPopupParagraph>
            <RichText
              richText={Footer?.[FooterLevelThreeIndex]?.richText}
              themeName={Footer?.[FooterLevelThreeIndex]?.themeName}
            />
          </NewUserPopupParagraph>
        </React.Fragment>
      ) : (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      )}
    </Container>
  );
};

export default SearchTrackOrder;

SearchTrackOrder.propTypes = {
  Header: PropTypes.array,
  ButtonCheckStatus: PropTypes.object,
  SignInRichText: PropTypes.object,
  Footer: PropTypes.array,
  errorMessages: PropTypes.object,
  UseReCaptcha: PropTypes.func
};
