// 3RD PARTY IMPORTS //
import styled from "styled-components";

// CONSTANT IMPORTS //
import { Colors, Typography } from "@serenaandlily/constants/constants";

export const RecaptchaText = styled.p`
  font-size: 11px;
  font-family: ${Typography?.BeatriceLight};

  color: ${Colors?.Midnight};

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  margin: 24px 0 0 0;
`;

export const RecaptchaTextLink = styled.a`
  font-size: 11px;
  font-family: ${Typography?.BeatriceLight};

  color: ${Colors?.Midnight};
`;
