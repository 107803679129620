import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  Typography,
  FontSizes,
  FontWeights
} from "../../constants/constants";

export const Container = styled.div`
  max-width: none;
  background: ${Colors.White};
  color: ${Colors["Dark Primary"]};
  box-sizing: border-box;
  margin: 20px;
  text-align: center;
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    margin: 20px;
    max-width: 90%;
    margin-left: 3%;
    padding-right: 5%;
  }
  & p {
    line-height: 200%;
  }
`;

export const FormContainer = styled.form`
  font-size: ${FontSizes.Base};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  & div {
    width: 100%;
    max-width: 300px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    & label {
      text-transform: uppercase;
    }
    & div {
      font-size: ${FontSizes.Base};
    }
  }

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    & div {
      max-width: none;
    }
  }
`;

export const SubmitButton = styled.button`
  width: 320px;
  display: inline-block;
  padding: 0;
  text-align: center;
  height: 34px;

  background: ${Colors["Dark Primary"]};
  color: ${Colors.White};
  font-size: ${FontSizes.Base};
  font-family: ${Typography.ProximaNova};
  font-style: ${FontWeights.normal};
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.08em;
  border: none;

  &:hover {
    background: ${Colors["Medium Primary"]};
  }

  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    width: 100%;
  }
`;

export const NewUserPopupParagraph = styled.div`
  text-align: center;
  position: relative;
  margin: 0px auto;
  padding: 20px 0px;
  box-sizing: border-box;
  letter-spacing: 1px;
  line-height: 20px;
  width: 90%;
  font-style: ${FontWeights.normal};
  font-stretch: normal;
  max-width: 350px;
  p {
    margin: 0;
    line-height: 20px;
    font-size: ${FontSizes.Base};
  }
`;

export const NewUserPopupParagraphWithBorderBottom = styled.div`
  text-align: center;
  position: relative;
  margin: 0px auto;
  padding: 20px 0px;
  box-sizing: border-box;
  letter-spacing: 1px;
  line-height: 20px;
  width: 90%;
  font-style: ${FontWeights.normal};
  font-stretch: normal;
  max-width: 350px;
  border-bottom: 1px solid ${Colors["Light"]};
  p {
    margin: 0;
    line-height: 20px;
    font-size: ${FontSizes.Base};
  }
`;

export const ButtonWrapper = styled.div`
  margin: 20px 0;
  text-transform: uppercase;
  padding-bottom: 6%;
  & a {
    max-width: 320px;
    width: 100%;
  }

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    border-bottom: none;
    padding-bottom: 0;
    & a {
      width: 74%;
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0;
`;
