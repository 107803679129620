import apolloClient from "../client";
import buildGetOrderSearchV2 from "../queries/buildGetOrderSearchV2";

export const fetchOrderSearchV2 = async ({
  customerEmail,
  orderNumber,
  isWebOrder,
  billingZipCode,
  isPhoneNumber,
  pageNumber = 1,
  pageSize = 15,
  phoneNumber
}) => {
  if (!apolloClient) {
    console.error("Apollo Client is not defined");
    return;
  }

  const variables = {};

  if (customerEmail) {
    variables.customerEmail = customerEmail;
  }

  if (orderNumber && isWebOrder == false) {
    variables.orderNumber = orderNumber;
  }

  if (isWebOrder) {
    variables.webOrderNumber = orderNumber;
  }

  if (billingZipCode) {
    variables.zip = billingZipCode;
  }

  if (isPhoneNumber) {
    variables.phoneNumber = orderNumber;
  }

  if (phoneNumber) {
    variables.phoneNumber = phoneNumber;
  }

  const GET_ORDER = buildGetOrderSearchV2();

  try {
    const { data } = await apolloClient.query({
      query: GET_ORDER,
      variables: {
        order: {
          ...variables,
          pageNumber,
          pageSize
        }
      },
      errorPolicy: "none",
      context: { clientName: "endpoint2" }
    });

    return data?.orderSearchV2;
  } catch (error) {
    console.error("Error fetching order search:", error);
    // Handle error appropriately, maybe return a fallback value or rethrow the error
    return null;
  }
};

export default fetchOrderSearchV2;
